import React from "react";
import styles from "./TwoFactor.module.scss";
import { ReactComponent as Icon } from "../../assets/login-icon.svg";
import LoginForm from "../LoginForm/LoginForm";
import TwoFactorInput from "../TwoFactorInput/TwoFactorInput";
import DidNotGet from "../DidNotGet/DidNotGet";
import { ReactComponent as SearchDevice } from "../../assets/searchDevice.svg";
import { ReactComponent as ManageDeivce } from "../../assets/manageDevice.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TwoFactor = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <Icon width={160} height={160} />
        <h2>{t("2fa")}</h2>
        {/* <LoginForm fnc={setLoading} /> */}
        <TwoFactorInput />
        <p className={styles.sent}>{t("devices")}</p>
        <DidNotGet />
        <div className={styles.divider}></div>
        <p className={styles.stolen}>{t("lost")}</p>
        <ul className={styles.bottomBlock}>
          <li>
            <Link to="">
              <SearchDevice />
              <p>{t("find")}</p>
            </Link>
          </li>
          <li>
            <Link to="/">
              <ManageDeivce />
              <p>{t("manage")}</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TwoFactor;
