import React, { useEffect } from "react";
import Navbar from "../../widgets/Navbar/Navbar";
import Footer from "../../widgets/Footer/Footer";
import TwoFactor from "../../components/TwoFactor/TwoFactor";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoading, selectUser } from "../../features/userSlice";
import Loader from "../../components/Loader/Loader";

const TwoFactorPage = () => {
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const user = useSelector(selectUser)


  useEffect(() => {
    if(!user) {
        navigate('/')
    }
  }, []);
  return (
    <>
      <Navbar />
      <main>
        {loading ? <Loader /> : ""}
        {!loading && <TwoFactor />}
      </main>
      <Footer />
    </>
  );
};

export default TwoFactorPage;
