import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.left}>
          <Link
            target="_blank"
            to="https://www.apple.com/support/systemstatus/"
          >
            {t("status")}
          </Link>
          <Link target="_blank" to="https://www.apple.com/legal/privacy/">
            {t("privacy")}
          </Link>
          <Link
            target="_blank"
            to="https://www.apple.com/legal/internet-services/icloud/"
          >
            {t("terms")}
          </Link>
        </div>
        <small className={styles.right}>
          {t("copy")}
        </small>
      </div>
    </div>
  );
};

export default Footer;
