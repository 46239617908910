import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./AdminPanel.module.scss"; // Путь к файлу со стилями

const AdminPanel = ({ isAdmin }) => {
  const [proxies, setProxies] = useState("");
  const [proxiesRu, setProxiesRu] = useState("");
  const [proxiesUa, setProxiesUa] = useState("");
  const [proxiesEng, setProxiesEng] = useState("");
  const [proxiesEu, setProxiesEu] = useState("");
  const [proxiesUs, setProxiesUs] = useState("");
  const [block, setBlock] = useState("");
  const [auth, setAuth] = useState("");

  useEffect(() => {
    if (!isAdmin) {
      // Редирект или отображение ошибки, если пользователь не администратор
      return;
    }

    // Получение прокси из базы данных
    axios
      .get(`${process.env.REACT_APP_API_URL}/proxies`)
      .then((response) => {
        const ruProxies = [];
        const uaProxies = [];
        const engProxies = [];
        const euProxies = [];
        const usProxies = [];

        response.data.forEach((proxy) => {
          switch (proxy.country) {
            case "ru":
              ruProxies.push(proxy.proxy);
              break;
            case "ua":
              uaProxies.push(proxy.proxy);
              break;
            case "eng":
              engProxies.push(proxy.proxy);
              break;
            case "eu":
              euProxies.push(proxy.proxy);
              break;
            case "us":
              usProxies.push(proxy.proxy);
              break;
            default:
              break;
          }
        });

        setProxiesRu(ruProxies.join("\n"));
        setProxiesUa(uaProxies.join("\n"));
        setProxiesEng(engProxies.join("\n"));
        setProxiesEu(euProxies.join("\n"));
        setProxiesUs(usProxies.join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при получении прокси:", error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/block`)
      .then((response) => {
        const arr = response.data.map((acc) => acc.blockedString);

        setBlock(arr.join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при получении блоклиста:", error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/block-auth`)
      .then((response) => {
        const arr = response.data.map((acc) => acc.username);

        setAuth(arr.join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при получении авторизованных юзеров:", error);
      });
  }, [isAdmin]);

  const handleAddProxies = () => {
    const proxiesArray = [
      ...proxiesRu
        .split("\n")
        .filter(Boolean)
        .map((proxy) => ({ proxy, country: "ru" })),
      ...proxiesUa
        .split("\n")
        .filter(Boolean)
        .map((proxy) => ({ proxy, country: "ua" })),
      ...proxiesEng
        .split("\n")
        .filter(Boolean)
        .map((proxy) => ({ proxy, country: "eng" })),
      ...proxiesEu
        .split("\n")
        .filter(Boolean)
        .map((proxy) => ({ proxy, country: "eu" })),
      ...proxiesUs
        .split("\n")
        .filter(Boolean)
        .map((proxy) => ({ proxy, country: "us" })),
    ];

    // Отправка запроса на сервер для добавления прокси
    axios
      .post(`${process.env.REACT_APP_API_URL}/proxies`, {
        proxies: proxiesArray,
      })
      .then((response) => {
        console.log(response.data.msg);
        alert("Прокси успешно добавлены");

        // Обновление состояния для всех стран после успешного добавления
        const updatedProxies = response.data;

        setProxiesRu(
          updatedProxies
            .filter((proxy) => proxy.country === "ru")
            .map((proxy) => proxy.proxy)
            .join("\n")
        );
        setProxiesUa(
          updatedProxies
            .filter((proxy) => proxy.country === "ua")
            .map((proxy) => proxy.proxy)
            .join("\n")
        );
        setProxiesEng(
          updatedProxies
            .filter((proxy) => proxy.country === "eng")
            .map((proxy) => proxy.proxy)
            .join("\n")
        );
        setProxiesEu(
          updatedProxies
            .filter((proxy) => proxy.country === "eu")
            .map((proxy) => proxy.proxy)
            .join("\n")
        );
        setProxiesUs(
          updatedProxies
            .filter((proxy) => proxy.country === "us")
            .map((proxy) => proxy.proxy)
            .join("\n")
        );
      })
      .catch((error) => {
        console.error("Ошибка при добавлении прокси:", error);
      });
  };

  const handleAddBlock = () => {
    const blockArray = block.split("\n").filter(Boolean);

    // Отправка запроса на сервер для добавления прокси
    axios
      .post(`${process.env.REACT_APP_API_URL}/block`, {
        blockedList: blockArray,
      })
      .then((response) => {
        console.log(response.data.msg);
        alert("Заблокированные пользователи успешно добавлены");
        setBlock(
          response.data.map((blocked) => blocked.blockedString).join("\n")
        );
      })
      .catch((error) => {
        console.error("Ошибка при добавлении прокси:", error);
      });
  };

  const handleAddAuth = () => {
    const blockArray = auth.split("\n").filter(Boolean);

    // Отправка запроса на сервер для добавления прокси
    axios
      .post(`${process.env.REACT_APP_API_URL}/block-auth`, {
        blockedList: blockArray,
      })
      .then((response) => {
        console.log(response.data.msg);
        alert("Авторизованные пользователи успешно добавлены");
        setAuth(response.data.map((acc) => acc.username).join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при добавлении прокси:", error);
      });
  };

  return (
    <div className={styles.adminPanel}>
      <div className={styles.block}>
        <h2>Добавление прокси RU</h2>
        <textarea
          value={proxiesRu}
          onChange={(e) => setProxiesRu(e.target.value)}
          placeholder="Введите прокси, каждый с новой строки"
        />
        <button onClick={handleAddProxies}>Добавить прокси</button>
      </div>
      <div className={styles.block}>
        <h2>Добавление прокси UA</h2>
        <textarea
          value={proxiesUa}
          onChange={(e) => setProxiesUa(e.target.value)}
          placeholder="Введите прокси, каждый с новой строки"
        />
        <button onClick={handleAddProxies}>Добавить прокси</button>
      </div>
      <div className={styles.block}>
        <h2>Добавление прокси ENG</h2>
        <textarea
          value={proxiesEng}
          onChange={(e) => setProxiesEng(e.target.value)}
          placeholder="Введите прокси, каждый с новой строки"
        />
        <button onClick={handleAddProxies}>Добавить прокси</button>
      </div>
      <div className={styles.block}>
        <h2>Добавление прокси EU</h2>
        <textarea
          value={proxiesEu}
          onChange={(e) => setProxiesEu(e.target.value)}
          placeholder="Введите прокси, каждый с новой строки"
        />
        <button onClick={handleAddProxies}>Добавить прокси</button>
      </div>
      <div className={styles.block}>
        <h2>Добавление прокси US</h2>
        <textarea
          value={proxiesUs}
          onChange={(e) => setProxiesUs(e.target.value)}
          placeholder="Введите прокси, каждый с новой строки"
        />
        <button onClick={handleAddProxies}>Добавить прокси</button>
      </div>
      <div className={styles.block}>
        <h2>Добавление блоклиста</h2>
        <textarea
          value={block}
          onChange={(e) => setBlock(e.target.value)}
          placeholder="Введите заблокированные аккаунты, каждый с новой строки"
        />
        <button onClick={handleAddBlock}>Добавить блоклист</button>
      </div>
      <div className={styles.block}>
        <h2>Добавление Авторизованных</h2>
        <textarea
          value={auth}
          onChange={(e) => setAuth(e.target.value)}
          placeholder="Введите заблокированные аккаунты, каждый с новой строки"
        />
        <button onClick={handleAddAuth}>Добавить пользователей</button>
      </div>
    </div>
  );
};

export default AdminPanel;
