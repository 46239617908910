import React from 'react'
import styles from './Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loader}>
<svg
  className="loading-spinner"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 48 48"
  width={48}
>
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(  0, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate( 30, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate( 60, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate( 90, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(120, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(150, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(180, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(210, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(240, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(270, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(300, 24, 24)"
  />
  <polyline
    className="loading-spinner__line"
    points="24,12 24,4"
    transform="rotate(330, 24, 24)"
  />
</svg>

    </div>
  )
}

export default Loader