import Resend from "../assets/resend.png"
import Sms from "../assets/sms.png"
import Other from "../assets/other.png"

export const resend = [
    {
        img: Resend,
        title: "resend",
        text: "resendtext",
        type: 'resend'
    },
    {
        img: Sms,
        title: "recall",
        text: "recalltext",
        type: 'recall'
    },
    {
        img: Other,
        title: "more",
        text: "moretext",
        type: "other"
    },
]