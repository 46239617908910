import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import { Provider } from "react-redux";
import {store} from './app/store'
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/drive">
      <App />
    </BrowserRouter>
  </Provider>
);
