import React, { useState } from "react";
import styles from "./LoginComponent.module.scss";
import { ReactComponent as Icon } from "../../assets/login-icon.svg";
import LoginForm from "../LoginForm/LoginForm";
import {Link} from 'react-router-dom'
import Loader from "../Loader/Loader";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectLoading } from "../../features/userSlice";
import { useTranslation } from "react-i18next";

const LoginComponent = () => {
  const loading = useSelector(selectLoading)
  const {t} = useTranslation()

  return (
    <div className={classNames(styles.login, {[styles.active]: !loading})}>
      <div className={styles.container}>
        <Icon width={160} height={160} />
        <h2>{t("login")}</h2>
        <LoginForm />
        <label className={styles.check}>
          <input type="checkbox" />
          {t("keepsign")}
        </label>
      <div className={styles.links}>
      <Link target="_blank" to="https://iforgot.apple.com/password/verify/appleid">
          {t("forgot")}
        </Link>
        <Link to="/">
          {t("create")}
        </Link>
      </div>
      </div>
    </div>
  );
};

export default LoginComponent;
