// slices/userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
  user: null,
  loading: false,
};


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  },
});

export const { setLoading, setUser } = userSlice.actions;

export const selectLoading = (state) => state.user.loading;
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
