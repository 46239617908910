import React, { useState } from "react";
import styles from "./DidNotGet.module.scss";
import { resend } from "../../data/resend";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { selectUser } from './../../features/userSlice';
import axios from "axios";

const DidNotGet = () => {
  const [isPopupActive, setIsPopupActive] = useState(false);
  const {t} = useTranslation()
  const user = useSelector(selectUser)

  const handleResend = async (type) => {
    setIsPopupActive(false)
    if(type === 'other') {
      setIsPopupActive(false)
      return
    };

    try {
      const {data} = axios.post(`${process.env.REACT_APP_API_URL}/resend/${user._id}`, {type})
      setIsPopupActive(false)
      console.log(data);
    } catch (error) {
      setIsPopupActive(false)
    }
  };
  return (
    <div className={styles.wrap}>
      <button
        onClick={() => setIsPopupActive((prev) => !prev)}
        className={styles.message}
      >
        {t("didntget")}
      </button>
      <ul
        className={classNames(styles.resend, {
          [styles.active]: isPopupActive,
        })}
      >
        {resend.map(({ img, title, text, type }) => {
          return (
            <li onClick={() => handleResend(type)} key={t(title)} className={styles.resendItem}>
              <img src={img} alt={t(title)} />
              <button>{t(title)}</button>
              <p>{t(text)}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DidNotGet;
